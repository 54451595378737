<template>
  <div class="main_cont">

    <div v-if="runOnPC">
      <p style="font-size: 25px;">Only mobile devices</p>
    </div>
    <div v-else>
      <div v-if="errorUID">
        <p style="font-size: 25px;">No user, please try again</p>
      </div>
      <div v-else>
        <p style="font-size: 25px;">Welcome, {{ username }}</p>
        <p style="font-weight: 400;">Your ID: {{ userId }}</p>
      </div>

    </div>


  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      userId: '',
      errorUID: '',
      runOnPC: '',
    };
  },
  mounted() {
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;

      // Выводим в консоль объект tg.initDataUnsafe для отладки
      //console.log(tg.initDataUnsafe);

      if (tg.platform === 'android' || tg.platform === 'ios') {
        if (tg.initDataUnsafe && tg.initDataUnsafe.user) {
          this.username = tg.initDataUnsafe.user.username;
          this.userId = tg.initDataUnsafe.user.id;
        } else {
          //Если проблема с получением даных пользователя
          this.errorUID = 1;
        }
      } else {
        //если запущено на РС
        //поставить 1 при загрузке на прод! и убрать установку пользователя
        this.runOnPC = 0;
        this.username = tg.initDataUnsafe.user.username;
        this.userId = tg.initDataUnsafe.user.id;

      }

      tg.ready();
    }
  }

};
</script>


<style>

.main_cont {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: #302B4F;
  color: #ffffFF;
}


</style>